import { StyledModal } from "./StyledModal";
import { observer } from "mobx-react-lite";
import { ColumnBox } from "../common/ColumnBox";
import { ModalView } from "./common/ModalView";
import { ModalStore } from "@root/stores/modalStore";
import { Box, Button, Link, Typography } from "@mui/material";

type ConfirmModalProps = {
  store: ModalStore;
  title: string;
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
};

export const ConfirmModal = observer(
  ({ store, title, message, onConfirm, onCancel }: ConfirmModalProps) => {
    return (
      <StyledModal open={store.isOpen} onClose={() => onCancel()}>
        <ColumnBox>
          <ModalView title={title}>
            <Typography variant="body2" sx={{ textAlign: "center", whiteSpace: "pre-wrap" }}>
              {message}
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "2rem",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Link
                onClick={() => onCancel()}
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  fontWeight: 500,
                  textDecoration: "none",
                }}
              >
                Cancel
              </Link>
              <Button
                type="button"
                onClick={() => onConfirm()}
                sx={{ width: "fit-content" }}
              >
                Yes
              </Button>
            </Box>
          </ModalView>
        </ColumnBox>
      </StyledModal>
    );
  }
);
